import axios from 'axios';
import { apiUrl} from'../Config/Config';

const mem_plan = () => {
    let planLists = [];
    axios.post(apiUrl+"membership/plantypes").then(res => {
        if(res.data.status == "success"){
            let i=0;
            let planlist = res.data.planlist;
            
            for(i; i<planlist.length; i++){
                planLists[i] = { value: planlist[i]['id'], label: planlist[i]['title'] };
            }
            
        }       
    });
    return planLists;
}

const categories = () => {
    var qs = require('qs');
    var postobject = {};

    let catelists = [];
    axios.post(apiUrl+"faq/categories",qs.stringify(postobject)).then(res => {
        if(res.data.status == "success"){
            let i=0;
            let catelist = res.data.catelist;
            
            for(i; i<catelist.length; i++){
                catelists[i] = { value: catelist[i]['id'], label: catelist[i]['cate_name'] };
            }
            
        }       
    });
    return catelists;
}

const adcategories = () => {
    var qs = require('qs');
    var postobject = {};

    let catelists = [];
    axios.post(apiUrl+"advertisement/categories",qs.stringify(postobject)).then(res => {
        if(res.data.status == "success"){
            let i=0;
            let catelist = res.data.catelist;
            
            for(i; i<catelist.length; i++){
                catelists[i] = { value: catelist[i]['id'], label: catelist[i]['cate_name'] };
            }
            
        }       
    });
    return catelists;
}

const categories1 = () => {
    var qs = require('qs');

    var postobject = {
        type: 'all'
    };

    let catelists = [];
    axios.post(apiUrl+"faq/categories",qs.stringify(postobject)).then(res => {
        if(res.data.status == "success"){
            let i=0;
            let catelist = res.data.catelist;
            
            for(i; i<catelist.length; i++){
                catelists[i] = { value: catelist[i]['id'], label: catelist[i]['cate_name'] };
            }
            
        }       
    });
    return catelists;
}


const year_list = () => {

    let years = [];  
    let start_yr = 1950;
    let end_yr = new Date().getFullYear();
    let diff = end_yr - start_yr;
    // console.log(end_yr, 'end_yr');
    // console.log(diff, 'diff');
    let i=0;
    for(i; i<=diff; i++){
        let opt = start_yr + i;
        years[i] = { value: opt, label: opt };
    }
    return years;
}
const domainList = () => {
    let domainlist = [];
    axios.post(apiUrl+"user/domainlist").then(res => {
        if(res.data.status == "success"){
            let i=0;
            let domainlists = res.data.domainlist;
            
            for(i; i<domainlists.length; i++){
                domainlist[i] = { value: domainlists[i]['domain_id'], label: domainlists[i]['domain_name'] };
            }
            
        }       
    });
   
    return domainlist;
}
export const lang = {

    common: {
        title:'Fund',
        status_option:[
            { value: 'active', label: 'Active' },
            { value: 'inactive', label: 'Inactive' },
        ],
        membership_option_for:[
            { value: 'lender', label: 'Lender' },
            { value: 'broker', label: 'Broker' },
            { value: 'merchant', label: 'Merchant' },
        ],
        amounttype_option:[
            { value: 'merchant', label: 'Merchant' },
            { value: 'enterprises', label: 'Enterprises' },
        ],
        pluginWidth_option:[
            { value: '600', label: '600px' },
            { value: '630', label: '630px' },
        ],
        gender_option: [
            { value: 'Male', label: 'Male' },
            { value: 'Female', label: 'Female' }
        ],
        period_option: [
            // { value: 'Day', label: 'Day' },
            { value: 'Month', label: 'Month' },
            { value: 'Year', label: 'Year' }
        ],
        mem_plan_option: mem_plan(),
        categories_option: categories(),
        adcategories_option: adcategories(),
        categories_option1: categories1(),
        year_option: year_list(),
        businessTime: [
            { value: '6', label: 'Less than 6 months' },
            { value: '12', label: '6-12 months' },
            { value: '24', label: '1 year' },
            { value: '60', label: '2-3 years' },
            { value: '90', label: '3-5 years' },
            { value: '120', label: 'More than 5 years' }
        ],
        // [
        //     // { value: 'Day', label: 'Day' },
        //     { value: '1', label: 'Plan 1' },
        //     { value: '2', label: 'Plan 2' },
        //     { value: '3', label: 'Plan 3' }
        // ],
		/* image_size_option: [
            { value: '250x250', label: '250x250' },
			{ value: '350x350', label: '350x350' },
            { value: '520x520', label: '520X520' }
        ],*/
        image_size_option: [
            { value: '1031x400', label: '1031x400 (W X H)' },
            { value: '250x180', label: '250x180 (W X H)' },
			{ value: '200x150', label: '200x150 (W X H)' },
            { value: '180x120', label: '180x120 (W X H)' }
        ],
        coming_source :[
            { value: '0', label: 'Select' },
            { value: 'friends', label: 'Friends' },
            { value: 'facebook', label: 'Facebook' },
            { value: 'instagram', label: 'Instagram' },
            { value: 'messaging apps', label: 'Messaging Apps' },
            { value: 'website', label: 'Website' },
            { value: 'flyers', label: 'Flyers' },
            { value: 'sms', label: 'Sms' },
            { value: 'newspaper', label: 'Newspapers' },
            { value: 'others', label: 'Others' }
        ],
         /*field_options :[
            { value: 'text', label: 'Text' },
            { value: 'email', label: 'Email' },
            { value: 'tel', label: 'Tel' },
            { value: 'textarea', label: 'Textarea' },
            { value: 'password', label: 'Password' },
            { value: 'select', label: 'Select' },
            { value: 'checkbox', label: 'Checkbox' },
            { value: 'radio', label: 'Radio' },
            { value: 'url', label: 'url' },
            { value: 'image', label: 'Image' },
        ],*/
		field_options :[
            { value: 'showUsersAppList', label: 'User`s request'},
            { value: 'showLeadResponse', label: 'User`s lead response'},
            { value: 'showCreditCheck', label: 'Credit check'},
            { value: 'showUserstheAppform', label: 'Loan application form'},
            { value: 'showUploadDocuments', label: 'User`s upload documents'},
            { value: 'showUserstheAdditionalform', label: 'User`s complete loan form'},
            { value: 'lenderMainTab', label: 'Lender Main Tab'},
            { value: 'leadMainTab', label: 'Lead Main Tab'},
            { value: 'investorMainTab', label: 'Investor Main Tab'},

            { value: 'showUsersAppList', label: 'Investor`s Leads'},
            { value: 'showInterests', label: 'Investor`s Interests'},
            { value: 'showInvLender', label: 'Investor`s My lenders'},
            { value: 'showInvRequest', label: 'Investor`s Lenders Request'},
            { value: 'showInviteLender', label: 'Investor`s Lenders Invitation'},
            
            { value: 'showUsersAppList', label: 'Lender`s Buy Leads'},
            { value: 'showMyInvestors', label: 'Lender`s My Investor'},
            { value: 'showArchived', label: 'Lender`s Archived'},
            { value: 'showMyLeadRequest', label: 'Lender`s Lead'},
            { value: 'showTransaction', label: 'Lender`s History'},

            { value: 'showUsersList', label: 'Company`s Users list'},
            { value: 'showLeadsList-all', label: 'Company`s Leads list'},
            { value: 'showLeadsList-sold', label: 'Company`s Sold Lead'},
            { value: 'showLeadsList-funded', label: 'Company`s Funded Lead'},
            { value: 'showAllInvestors', label: 'Company`s All Investors'},
            { value: 'showLendersList-active', label: 'Company`s Lenders Active'},
            { value: 'showLendersList-inactive', label: 'Company`s Lenders Inactive'},
            { value: 'showTransactionsList', label: 'Company`s Monthly Earnings'},
            { value: 'showTransactionsList', label: 'Company`s Yearly Earnings'},
            { value: 'showTransactionsList', label: 'Company`s Purchased Leads'},
            //{ value: 'showMyInterests', label: 'My interests'},            
        ],

        user_type_options :[            
            { value: 'choose', label: 'Before Login'},
            { value: 'user', label: 'User'},
            { value: 'lender', label: 'Lender'},
            { value: 'investor', label: 'Investor'},        
            { value: 'broker', label: 'Broker'},        
            { value: 'salesperson', label: 'Sales Person'},        
            { value: 'company', label: 'Company'},        
        ],

        before_login_ads_options :[            
            { value: 'lenderMainTab', label: 'Lender Main Tab'},
            { value: 'leadMainTab', label: 'Lead Main Tab'},
            { value: 'investorMainTab', label: 'Investor Main Tab'},   
            { value: 'triggerForm', label: 'Loan application form'},     
        ],

        user_ads_options :[
            { value: 'showUsersAppList', label: 'User`s request'},
            { value: 'showLeadResponse', label: 'User`s lead response'},
            { value: 'showCreditCheck', label: 'Credit check'},
            { value: 'showUserstheAppform', label: 'Loan application form'},
            { value: 'showUploadDocuments', label: 'User`s upload documents'},
            { value: 'showUserstheAdditionalform', label: 'User`s complete loan form'},
            { value: 'lenderMainTab', label: 'Lender Main Tab'},
            { value: 'leadMainTab', label: 'Lead Main Tab'},
            { value: 'investorMainTab', label: 'Investor Main Tab'},        
        ],

        investor_ads_options :[            
            { value: 'showUsersAppList', label: 'Investor`s Leads'},
            { value: 'showInterests', label: 'Investor`s Interests'},
            { value: 'showInvLender', label: 'Investor`s My lenders'},                          
        ],

        lender_ads_options :[           
            { value: 'showUsersAppList', label: 'Lender`s Buy Leads'},
            { value: 'showMyInvestors', label: 'Lender`s My Investor'},
            { value: 'showArchived', label: 'Lender`s Archived'},
            { value: 'showMyLeadRequest', label: 'Lender`s Lead'},                  
        ],

        company_ads_options :[           
            { value: 'showUsersList', label: 'Company`s Users list'},
            { value: 'showLeadsList-all', label: 'Company`s Leads list'},
            { value: 'showLeadsList-sold', label: 'Company`s Sold Lead'},
            { value: 'showLeadsList-funded', label: 'Company`s Funded Lead'},
            { value: 'showAllInvestors', label: 'Company`s All Investors'},                  
        ],
        
        sales_ads_options :[           
            { value: 'showAssignedLeads', label: 'Sale person`s Assigned Leads'},                             
        ],
       
        broker_ads_options :[           
            { value: 'showUsersAppList', label: 'Broker`s Buy Leads'},
            { value: 'showMyLeadRequest', label: 'Broker`s Leads'},
        ],

        discount_option:[
            { value: 'percentage', label: 'Percentage' },
            { value: 'standard', label: 'Standard' },
        ],
        domainList_option:domainList(),
        loantype_option:[            
            { value: 'Business', label: 'Business' },
            { value: 'Education', label: 'Education' },
            { value: 'Personal', label: 'Personal' },
            { value: 'Startup', label: 'Startup' },
            { value: 'Others', label: 'Others' },
        ],
        leadsType_option:[            
            { value: '', label: 'All' },
            { value: 'funded', label: 'Funded Leads' },
            { value: 'sold', label: 'Sold Leads' },
        ],
		 login_type:[
            { value: 'merchant', label: 'Merchant' },
            { value: 'lender', label: 'Lender' },
            { value: 'investor', label: 'Investor' },
            { value: 'company', label: 'Company' },
        ],
    },
    Capitalize(str){
        return str.charAt(0).toUpperCase() + str.slice(1);
        }
};


